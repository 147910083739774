/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import "core-js/stable";
import "regenerator-runtime/runtime";
import 'whatwg-fetch'

//jquery
import * as $ from "jquery";
window.$ = $;
window.jQuery = $;

// images
require.context('../images', true)

//bootstrap
require('bootstrap');

//fontawesome
import fontawesome from '@fortawesome/fontawesome'
// https://fontawesome.com/how-to-use/js-component-packages
import solid from '@fortawesome/fontawesome-free-solid'


// Support component names relative to this directory:
var componentRequireContext = require.context("components", true)
var ReactRailsUJS = require("react_ujs")
ReactRailsUJS.useContext(componentRequireContext)
